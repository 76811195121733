<template>
  <v-app-bar :elevation="0" dense :color="dark ? '#272727' : 'white'"
    v-if="itemsPerPage !== null && count !== null && startIndex !== null"
    
  >
    <div style="padding-left:40px;">
      <delete-item
        v-if="multiSelectedID && multiSelectedID.length > 0"
        :id="multiSelectedID.join(',')"
        :onDeleteItem="onDeleteItem"
        :isInList="true"
      />
      <v-btn
        v-if="multiSelectedID && multiSelectedID.length > 0 && onHideItem"
        color="#CCCCCC"
        dark
        xSmall
        style="margin-left: 10px;"
        @click="onHideItem"
      >
        <v-icon x-small>
          mdi-order-bool-ascending-variant
        </v-icon>
        <v-icon x-small>
          mdi-eye-off
        </v-icon>
      </v-btn>
      <v-btn
        v-if="multiSelectedID && multiSelectedID.length > 0 && onShowItem"
        color="green"
        dark
        xSmall
        style="margin-left: 10px;"
        @click="onShowItem"
      >
        <v-icon x-small>
          mdi-order-bool-ascending-variant
        </v-icon>
        <v-icon x-small>
          mdi-eye
        </v-icon>
      </v-btn>
    </div>
    <v-spacer />
    <span style="padding: 0 10px;">{{ info }}</span>
    <v-icon :disabled="disableStart" @click="handleChangePage(-itemsPerPage)">mdi-chevron-left</v-icon>
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          x-small
          v-if="onChangeItemsPerPage"
        >
        {{ itemsPerPage }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item of arrayPages ? arrayPages : [5, 10, 15, 20, 50, 100, 150]"
          :key="item"
          @click="handleChangeItemsPerPage(item)"
        >
        <v-list-item-title><center>{{ item }}</center></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-icon :disabled="disableEnd" @click="handleChangePage(itemsPerPage)" style="margin-left: 0px">mdi-chevron-right</v-icon>
  </v-app-bar>
</template>
<script>
import DeleteItem from '../DeleteItem'
export default {
  components: {
    DeleteItem,
  },
  props: {
    arrayPages: {
      type: Array,
      default: null,
    },
    startIndex: {
      type: Number,
      required: false,
      default: null
    },
    count: {
      type: Number,
      required: false,
      default: null
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: null
    },
    onGetData: {
      type: Function,
      required: true,
      default: null
    },
    onChangeItemsPerPage: {
      type: Function,
      default: null,
    },
    locale: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: false,
    },
    multiSelectedID: {
      type: Array,
      default: null,
    },
    onDeleteItem: {
      type: Function,
      default: null,
    },
    onHideItem: {
      type: Function,
      default: null,
    },
    onShowItem: {
      type: Function,
      default: null,
    },
  },
  data () {
    return {
      info: '',
      disableStart: true,
      disableEnd: true
    }
  },
  watch: {
    itemsPerPage() {
      this.setInfo()
    },
    startIndex () {
      this.setInfo()
    },
    count () {
      this.setInfo()
    }
  },
  mounted () {
    this.setInfo()
  },
  methods: {
    setInfo () {
      const end = this.startIndex + this.itemsPerPage
      const start = this.startIndex
      this.disableStart = start <= 0
      this.disableEnd = end >= this.count
      this.info = `${start}-${end > this.count ? this.count : end} ${this.$t('common.of', this.locale)} ${this.count}`
    },
    handleChangePage (v) {
      const aux = this.startIndex + v
      this.onGetData(aux < 0 ? 0 : aux)
    },
    handleChangeItemsPerPage (v) {
      this.onChangeItemsPerPage(v)
    },
  }
}
</script>

<style scoped>
  span {
    font-size: 0.75rem;
  }
</style>
