<template>
  <v-layout wrap align-center class="interval-time-main">
    <v-flex xs12>
      <v-icon @click="handleChangeValue(+1)" :disabled="max && max === data">      
        mdi-arrow-up-drop-circle-outline
      </v-icon>
    </v-flex>
    <v-flex v-if="!menus" xs12 class="interval-time-data" :style="{ 'color' : data === 0 ? 'lightgray' : 'white'}">
      <center>{{formatTime(data)}}</center>
    </v-flex>
    <v-flex v-else xs12 class="interval-time-data" :style="{ 'color' : data === 0 ? 'lightgray' : 'white'}">
      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <center v-bind="attrs" v-on="on">{{formatTime(data)}}</center>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in menus"
            :key="i"
            style="cursor: pointer"
          >
            <v-list-item-title @click="handleMenuClick(item.value)">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-flex>
    <v-flex xs12>
      <v-icon @click="handleChangeValue(-1)" :disabled="data === 0">
        mdi-arrow-down-drop-circle-outline
      </v-icon>
    </v-flex>
  </v-layout>
</template>

<script>
  import utils from '@/services/utils'
  export default {
    props: {
      value: {
        type: Number,
        required: true,
        default: 0
      },
      max: {
        type: Number,
        required: false,
        default: null
      }
    },
    data: () => ({
      data: 0,
      menus: null,
    }),
    watch: {
      value: {
        handler: function (newValue) {
          if (newValue != null) {
            this.data = newValue
          }
        },
        deep: true
      }
    },
    mounted () {
      this.prepareMenu()
      this.data = this.value
    },
    methods: {
      prepareMenu () {
        if (this.max === 24) {
          const aux = []
          for (let i = 0 ; i < 24 ; i += 2) {
            aux.push({ title: utils.pad(i), value: i })
          }
          this.menus = aux
        }
        if (this.max === 60) {
          this.menus = [
            { title: '00', value: 0 },
            { title: '15', value: 15 },
            { title: '30', value: 30 },
            { title: '45', value: 45 },
            { title: '59', value: 59 },
          ]
        }
      },
      formatTime (value) {
        return utils.pad(value)
      },
      handleMenuClick (v) {
        this.data = v
        this.$emit('input', this.data)
      },
      handleChangeValue(value) {
        if (value < 0) {
          // resta
          if (this.data > 0) this.data += value       
        } else {
          // suma
          if (this.data < this.max || !this.max) this.data += value
        }
        this.$emit('input', this.data)
      }
    }
  }
</script>
<style scoped>
  .interval-time-main {
    text-align: center;
  }
  .interval-time-data {
    padding: 0 10px !important;
    margin: 0 10px;
    background-color: gray;
    color: white;
    border-radius: 5px;
    text-align: right;
    font-size: 20px;
    font-weight: 500;
  }
</style>

