<template>
  <v-layout
    wrap
    class="interval"
  >
    <table>
      <tr>
        <td v-if="label" class="interval-label">
          {{label}}
        </td>
        <td style="width:70px;">
          <interval-time
            v-model="hours"
            :max="24"
            @input="handleChangeTime"
          />
        </td>
        <td style="width:70px;">
          <interval-time
            v-model="minutes"
            :max="60"
            @input="handleChangeTime"
          />
        </td>
      </tr>
    </table> 
  </v-layout>
</template>

<script>
  import IntervalTime from './IntervalTime'
  export default {
    components: {
      IntervalTime
    },
    props: {
      label: {
        type: String,
        required: false,
        default: null
      },
      value: {
        type: Number,
        required: false,
        default: 0
      }
    },
    data () {
      return {
        hours: 0,
        minutes: 0
      }
    },
    computed: {
    },
    watch: {
      value: {
        handler: function (newValue) {
          if (newValue) {
            this.getHoursMinutes (newValue)
          }
        },
        deep: true
      }
    },
    mounted (){
      this.getHoursMinutes(this.value ? this.value : 0)
    },
    methods: {
      getHoursMinutes (value) {
        let aux = value / 60

        this.hours = aux > 0 ? parseInt(aux) : 0
        this.minutes = value - (this.hours * 60)
      },
      handleChangeTime() {
        this.$emit('input', this.minutes + (this.hours * 60))
      }
    }
  }
</script>
<style scoped>
  .interval {
    padding-bottom: 20px;
  }
  .interval-label {
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
    /* font-size: 20px; */
    font-weight: 700;
    text-align: right;
  }
</style>
