<template>
  <v-text-field
    outlined
    append-icon="mdi-magnify"
    :prepend-inner-icon="showClear ? 'mdi-close' : ''"
    hide-details
    dense
    style="margin-right:10px;"
    :style="{ maxWidth: `${maxWidth}px` }"
    v-model="search"
    autofocus
    @click:append="handleSearch"
    @click:prepend-inner="handleClear"
    v-on:keyup.enter="handleSearch"
    :label="label"
  />
</template>
<script>
  export default{
    props: {
      onSearch: {
        type: Function,
        required: true
      },
      showClear: {
        type: Boolean,
        default: false,
      },
      maxWidth: {
        type: [String, Number],
        default: 300,
      },
      label: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      search: '',
    }),
    methods: {
      handleSearch () {
        this.onSearch(this.search)
      },
      handleClear () {
        this.search = ''
        this.handleSearch()
      },
    },
  }
</script>
