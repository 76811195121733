<template>
  <div style="padding: 20px;background-color: #CCCCCC80;">
    <v-row>
      <v-col
        cols="12"
        xs="12"
        md="6"
      >
        <v-btn-toggle
          v-if="contentTypeData.SelectableActivities"
          v-model="viewType"
          borderless
          color="white"
          background-color="primary"
          mandatory
          tile
        >
          <v-btn 
            value="manager" 

          >
            <span class="hidden-sm-and-down"
              style="color: gray;"
            >Modificar actividades</span>

            <v-icon right>
              mdi-ticket
            </v-icon>
          </v-btn>
          <v-btn 
            value="schedule"
          >
            <span class="hidden-sm-and-down"

              style="color: gray;"
            >Programar actividades</span>

            <v-icon right>
              mdi-calendar-plus
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        md="2"
        style="text-align: right;"
      >
      </v-col>
      <v-col
        cols="12"
        xs="12"
        md="2"
        style="text-align: right;"
      >
      </v-col>
      <v-col
        cols="12"
        xs="12"
        md="2"
        style="text-align: right;"
      >
        <export-excel
          v-if="viewType === 'schedule'"
          :workspaceID="workspaceID"
          :contentTypeDataID="contentTypeDataID"
          :contentTypeData="contentTypeData"
          :locale="locale"
        />

      </v-col>
    </v-row>
    <div>
    <schedule-view
      v-if="viewType === 'schedule' && languages"
      :languages="languages"
      :contentTypeDataID="contentTypeDataID"
      :contentTypeData="contentTypeData"
      :locale="locale"
      :workspaceID="workspaceID"
    />

    <manager-view
      v-if="viewType === 'manager' && languages && timezone"
      :timezone="timezone"
      :languages="languages"
      :contentTypeDataID="contentTypeDataID"
      :contentTypeData="contentTypeData"
      :locale="locale"
      :workspaceID="workspaceID"
    />
    </div>
  <!--table 
    v-if="viewType === 'schedule' && contentTypeDataID && weekDay"
    border="0" cellspacing="0" cellpadding="0"
    style="width: 100%;margin-bottom: 20px;border-radius: 5px;" 
  >
    <tr style="height: 100px;">
      <td colspan="7" style="text-align: center; padding: 10px;font-size:1.2rem;">
        <v-btn
          @click="() => { selectedWeek-- }"
            style="margin-right: 20px;"
        >
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
          <b> {{getTranslation(contentTypeData.Title, locale)}}</b> <small>[{{getRange(weekDay)}}]</small>
        <v-btn
          @click="() => { selectedWeek++ }"
            style="margin-left: 20px;"
        >
          <v-icon>mdi-arrow-right-thick</v-icon>
        </v-btn>
        <form-event
          v-if="contentTypeData"
          :value="editedItem"
          :onClose="handleCloseForm"
          :contentTypeData="contentTypeData"
          :contentTypeDataID="contentTypeDataID"
          :locale="locale"
        />
      </td>
    </tr>
    <tr style="height: 50px;">
      <th
        v-for="(day, index) of weekDay"
        :key="`day${day.date}`"
        style="width: 12.5%;padding: 5px 2px;color:black;" 
      >
        <v-btn 
          :dark="!day.old" 
          :color="day.old ? 'gray' : contentTypeData.Color" 
          block
          @click="handleAdd(day.date)"
        >
          {{ $t(`common.s${index}`, locale)}} {{day.date.split('-').pop()}}
          <v-icon right dense>mdi-plus</v-icon>
        </v-btn>
      </th>
    </tr>
    <tr>
      <td
        v-for="(day) of weekDay"
        :key="`list${day.date}`"
        style="width: 12.5%;padding: 5px 2px;color:black;vertical-align: top;" 
      >
        <list-day
          v-if="contentTypeDataID"
          :contentTypeData="contentTypeData"
          :contentTypeDataID="contentTypeDataID"
          :day="day"
          :onEdit="(v) => { editedItem = v }"
          :locale="locale"
          :forceRefresh="forceRefresh"
        />
      </td>
    </tr>
  </table-->
  </div>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import ScheduleView from './Schedule'
import ManagerView from './Manager'
import ExportExcel from './ExportExcel'
//import ListDay from './ListDay'
//import FormEvent from './Form'
export default {
  components: {
    ScheduleView,
    ManagerView,
    ExportExcel,
  //  ListDay,
  //  FormEvent,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    contentTypeDataID: {
      type: String,
      required: true,
    },
    contentTypeData: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    /*
    onEdit: {
      type: Function,
      requried: true,
    },*/
  },
  data: () => ({
    weekDay: null,
    selectedWeek: 0,
    editedItem: null,
    forceRefresh: null,
    viewType: 'schedule',
    languages: null,
    timezone: null,
  }),
  watch: {
    contentTypeDataID () {
      this.handleGetLanguages()
      this.handlePrepareWeekDay()
    },
    selectedWeek () {
      this.handlePrepareWeekDay()
    },
  },
  mounted () {
    this.handleGetLanguages()
    this.handlePrepareWeekDay()
  },
  methods: {
    async handleGetLanguages () {
      const workspace = await new Promise((resolve, reject) => api.getItem ('dviewcontent', 'v1/public/workspaces/', this.workspaceID)
          .then(response => {   
          resolve({ languages: response.Languages ? JSON.parse(response.Languages) : [], timezone: response.Timezone ? response.Timezone : 'Europe/London' })
          })
        .catch (err => {
          reject(err)
        }))
      this.timezone = workspace.timezone
      this.languages = workspace.languages
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    getRange (v) {
      let ini = v[0].date.split('-')
      ini.shift()
      let end = v[6].date.split('-')
      end.shift()
      return `del ${ini.join('/')} al ${end.join('/')}`
    },
    addDays (v, daysToAdd) {
      return new Date(new Date(v).setDate(new Date(v).getDate() + daysToAdd))
    },
    handlePrepareWeekDay () {
      const result = []
      const today = this.selectedWeek ? this.addDays(new Date(), this.selectedWeek * 7) : new Date()
      let actualDay = ((today.getDay() === 0 ? 6 : today.getDay()))*-1
      for (let i = 0; i < 7; i++) {
        actualDay++
        result.push({
          contentTypeDataID: this.contentTypeDataID,
          i,
          date: this.addDays(today, actualDay).toISOString().split('T').shift(),
          old: this.selectedWeek < 0 || (this.selectedWeek === 0 && actualDay < 0)
        })
      }
      this.weekDay = result
    },
    handleCloseForm (refresh) {
      if (refresh) this.forceRefresh = new Date().toISOString()
      this.editedItem = null
    },
    handleAdd (v) {
      this.editedItem = {
        CategoryID: this.contentTypeData.Categories[0].ID,
        Title: { es: '' },
        Description: { es: '' },
        Place: { es: ''},
        Time: 120,
        TimeEnd: 180,
        date: v,
        lmu: new Date().toISOString()
      }
    },
    handleExportToExcel () {
      alert('aaa')
    },
  },
}
</script>
