<template>
  <div
    v-if="contents && selectedCarouselItem !== null"
    class="screensaver"
    style="width: 100%;height:100%;"
  >
    <v-carousel 
      v-model="selectedCarouselItem"
      :cycle="false"
      :show-arrows="false" 
      hide-delimiters
      height="100%"
    >
      <v-carousel-item
        v-for="(item, i) in contents"
        :key="i"
        v-html="item.content"
      />
    </v-carousel>

  </div>
</template>
<script>
export default {
  name: 'CustomCarousel',
  props: {
    contents: {
      type: Array,
      default: null,
    },
    action: {
      type: Object,
      required: true,
    },
    onChangeContent: {
      type: Function,
      required: true,
    },
    onTick: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    t: null,
    timer: null,
    selectedCarouselItem: null,
    isPause: false,
  }),
  watch: {
    'action.forceUpdate' () {
      var video
      if (this.action.action === 'prev') {
        this.handleChangeContent(-1)
      } else if (this.action.action === 'next') {
        this.handleChangeContent()
      } else if (this.action.action === 'play') {
        const content = this.contents[this.selectedCarouselItem]
        //const duration = content ? content.duration * 1000 : 0
        if (content.type === 'mp4') {
          video = document.getElementById(`${content.id}`)
          //const timeToEnd = video.duration - video.currentTime
          video.play()
          //this.prepareTimeOut(timeToEnd | 0 * 1000)
        }
        this.isPause = false
      } else if (this.action.action === 'pause') {
        const content = this.contents[this.selectedCarouselItem]
        if (content.type === 'mp4') {
          video = document.getElementById(`${content.id}`)
          video.pause()
        }
        this.isPause = true
      }
    },
  },
  mounted () {
    this.selectedCarouselItem = 0
    this.handleInterval()
  },
  beforeUnmount () {
    if (this.timer) clearInterval(this.timer)
  },
  methods: {
    prepareTimeOut (duration) {
      /*
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        alert('addd')
        this.handleChangeContent()
      }, duration)
      */
      if (this.timer) clearInterval(this.timer)
      this.t = duration
      this.timer = setInterval(() => {
        if (!this.isPause) {
          this.onTick(this.t)
          if (this.t <= 0) {
            this.handleChangeContent()
          }
          this.t--
        }
      }, 1000)
    },
    handleInterval () {
      const content = this.contents[this.selectedCarouselItem]
      this.onChangeContent({
        count: this.contents.length,
        actualIndex: this.selectedCarouselItem + 1,
        content
      })
      const duration = content ? content.duration : 0// * 1000 : 0
      this.prepareTimeOut(duration)
      /*
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.handleChangeContent()
      }, duration)
      */
    },
    handleChangeContent (v) {
      if (v === -1) {
        this.selectedCarouselItem = this.selectedCarouselItem > 0 ? this.selectedCarouselItem -1 : this.contents.length -1
      } else {
        this.selectedCarouselItem = this.selectedCarouselItem < this.contents.length-1 ? this.selectedCarouselItem + 1 : 0
      }
      this.onChangeContent({
        count: this.contents.length,
        actualIndex: this.selectedCarouselItem + 1,
        content: this.contents[this.selectedCarouselItem],
      })
      this.handleInterval () 
    },
  },
}
</script>


