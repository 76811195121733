const { DateTime, Interval } = require('luxon')
module.exports = {
  convertToDate (v) {
    if (typeof v === 'string') return new Date(Date.parse(v.split('T').shift(), 'Y-m-d'))
    return v
  },
  luxonPrepareDate(v, timezone = 'Europe/London') {
    const aux = this.convertToDate(v)
    return DateTime.fromObject({year: aux.getFullYear(), month: aux.getMonth()+1, day: aux.getDate(), hour: 12 }, { zone: timezone, numberingSystem: 'beng'})
  },
  padLeft (v) {
    return ('00' + v).slice(-2)
  },
  getGateArrayBetweenTwoDates (startDate, endDate, timezone = 'Europe/London') {
    const s = this.luxonPrepareDate(startDate, timezone)
    const e = this.luxonPrepareDate(endDate, timezone)
    let diff = s.diff(e, ['days'])
    diff = diff.toObject().days
    diff = diff < 0 ? diff * -1 : diff
    diff = diff > 31 ? 31 : diff
    const result = []
    for (let i = 0; i <= diff; i++) {
      const lactual = s.plus({ days: i }).setZone(timezone)
      result.push(`${lactual.year}-${this.padLeft(lactual.month)}-${this.padLeft(lactual.day)}`)
    }
    return result
  }, 
  checkLuxonAvailability (v, date, timezone) {
    let { DateStart, DateEnd, NoEndDate, DaysWeek, DaysMonth, AllTimes, TimeStart, TimeEnd, Alt, Type } = v

    let d = this.luxonPrepareDate(date, timezone)
    // check startDate
    if (this.luxonPrepareDate(DateStart, timezone) > d) return { data: false, time: false, future: true}
    // check endDate
    if (!NoEndDate && DateEnd && this.luxonPrepareDate(DateEnd, timezone) < d) return { date: false, time: false, old: true}
    // checkDaysWeek
    DaysWeek = DaysWeek ? DaysWeek.map(item => item ? parseInt(item) : null) : null
    if (DaysWeek && DaysWeek.indexOf(d.weekday === 7 ? 0 : d.weekday) < 0) return { date: false, time: false, future: true}
    // checkDaysMonth
    DaysMonth = DaysMonth ? DaysMonth.map(item => item ? parseInt(item) : null) : null
    if (DaysMonth && DaysMonth.indexOf(d.day) < 0) return { date: false, time: false, future: true}

    // INI check alt
    let avaDate = true
    let avaFuture = false
    let avaAlt = false
    if (Type !== 'alldays' && Alt > 1) {
      if (Type === 'bymonth') {
        let s1 = this.luxonPrepareDate(DateStart, timezone)
        if (s1.day > 1) s1 = s1.plus({days: (s1.day-1) * - 1})
        let s2 = d
        if (s2.day > 1) s2 = s2.plus({days: (s2.day-1) * - 1})
        const i = Interval.fromDateTimes(s1, s2)
        const aux = i.length('months') | 0
        if (aux && aux % Alt > 0) avaDate = false
        avaFuture = !avaDate
        avaAlt = !avaDate
      } else {
        let s1 = this.luxonPrepareDate(DateStart, timezone)
        if (s1.weekday > 1) s1 = s1.plus({days: (s1.weekday-1) * - 1})
        let s2 = d
        if (s2.weekday > 1) s2 = s2.plus({days: (s2.weekday-1) * - 1})
        const i = Interval.fromDateTimes(s1, s2)
        const aux = i.length('weeks') | 0
        if (aux && aux % Alt > 0) avaDate = false
        avaFuture = !avaDate
        avaAlt = !avaDate
      }
    }
    // END check alt
    if (AllTimes) return { date: avaDate, time: true, future: avaFuture, alt: avaAlt }
    const t = DateTime.now().setZone(timezone).toObject()
    const nowTime = (t.hour * 60) + t.minute


    return { date: avaDate, time: TimeStart < nowTime && nowTime > TimeEnd ? true : false, future: avaFuture, alt: avaAlt }
  },
}
